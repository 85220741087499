@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animate.css";

*,
:after,
:before {
  border: 0 solid #e5e7eb;
}

body {
  margin: 0;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #e8e8e8;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #9ad1b7;
  border-radius: 100px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #e8e8e8;
  border-radius: 100px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 100px;
}
